<template>
  <b-overlay
      rounded="sm"
      :show="!data"
  >
    <b-card title="Status overzicht (aantal EAN's)">
      <app-echart-bar
        v-if="data"
        :option-data="data"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
  },
}
</script>
