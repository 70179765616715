<template>
  <b-overlay
    rounded="sm"
    :show="!tableData"
  >
    <b-card
      v-if="tableData"
      no-body
      class="card-company-table"
    >
      <b-table
        :items="tableData"
        responsive
        :fields="fields"
        class="mb-0"
      >
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.needsAttention === 0 || data.item.needsAttention === 1 && data.value === 'CANCELLED'"
            :id="`invoice-row-${data.item.id}-status`"
            :variant="getStatusType(data.value).variant"
          >
            {{ getStatusType(data.value).name }}
          </b-badge>
          <b-badge
            v-else
            :id="`invoice-row-${data.item.id}-status`"
            :variant="getStatusType('NEEDS_ATTENTION').variant"
          >
            {{ getStatusType('NEEDS_ATTENTION').name }}
          </b-badge>
        </template>
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold"
            :style="`color:${resolveColorVariant(data.item.status)}`"
            :to="{ name: 'app-order-detail', params: { id: data.item.id } }"
          >
            #{{ data.item.id }}
          </b-link>
        </template>
        <template #cell(customerRelation)="data">
          <b-media
            vertical-align="center"
          >
            <template #aside>
              <a
                href="/"
                class="b-avatar router-link-active badge-secondary rounded-circle"
                target="_self"
                style="width: 32px; height: 32px;"
              >
                <span
                  v-if="data.value.type === 1 && data.value.gender === 'f'"
                  class="b-avatar-text"
                  style="font-size: 20px"
                >
                  <span
                    style="font-size: 20px"
                    class="fas fa-female"
                  />
                </span>
                <span
                  v-if="data.value.type === 1 && data.value.gender === 'm'"
                  class="b-avatar-text"
                  style="font-size: 20px"
                >
                  <span
                    style="font-size: 20px"
                    class="fas fa-male"
                  />
                </span>
                <span
                  v-if="data.value.type === 1 && (!data.value.gender || data.value.gender === '')"
                  class="b-avatar-text"
                  style="font-size: 20px"
                >
                  <span
                    style="font-size: 20px"
                    class="fas fa-restroom"
                  />
                </span>
                <span
                  v-if="data.value.type === 2"
                  class="b-avatar-text"
                  style="font-size: 20px"
                >
                  <span
                    style="font-size: 20px"
                    class="fas fa-building"
                  />
                </span>
              </a>
            </template>
            <b-link
              :to="{ name: 'apps-relations-edit', params: { id: data.value.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.value.type === 1 ? getFullName(data.value) : data.value.companyName }}
            </b-link>
            <div class="text-muted">
              {{ data.item.product.name }}
            </div>
          </b-media>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(signTimestamp)="data">
          <span class="text-nowrap">
            <small>{{ data.value }}</small>
          </span>
        </template>

        <template #cell(user.fullname)="data">
          <span class="text-nowrap">
            <small>{{ data.value }}</small>
          </span>
        </template>
      </b-table>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BCard, BLink, BMedia, BOverlay, BTable,
} from 'bootstrap-vue'
import { formatDateTime } from '@core/utils/utils'
import useOrderList from '@/views/pages/order/useOrderList'

export default {
  components: {
    BCard,
    BTable,
    BOverlay,
    BLink,
    BMedia,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      fields: [
        { label: '#', key: 'id', sortable: false },
        { label: 'Relatie', key: 'customerRelation', sortable: true },
        { label: 'status', key: 'status', sortable: true },
        { label: 'Aangemaakt door', key: 'user.fullname', sortable: true },
        { label: 'Getekend op', key: 'signTimestamp', sortable: true },
      ],
    }
  },
  methods: {
    getFullName(data) {
      let fullName = ''

      if (data) {
        if (data.middleName && data.middleName.length > 0) {
          fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
        } else {
          fullName = `${data.firstName} ${data.lastName}`
        }
      }

      return fullName
    },
  },
  setup() {
    const {
      getStatusType,
      resolveColorVariant,
    } = useOrderList()

    return {
      formatDateTime,
      getStatusType,
      resolveColorVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include.scss';
@import '~@core/scss/base/components/_variables-dark.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
